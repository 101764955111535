/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-cloud-snow': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M20 17.58A5 5 0 0018 8h-1.26A8 8 0 104 16.25M8 16h0M8 20h0M12 18h0M12 22h0M16 16h0M16 20h0"/>',
	},
});
