













import Vue from 'vue';
import Notification from './Notification.vue';

const Notifications = Vue.extend({
	name: 'Notifications',

	components: {
		Notification,
	},

	computed: {
		notifications() {
			// @ts-ignore
			return Vue.NotificationStore.state;
		},
	},

	methods: {
		removeNotification(notification) {
			// @ts-ignore
			Vue.NotificationStore.remove(notification);
		},
	},
});

export default Notifications;
