import Vue from 'vue';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import App from '@/App.vue';
import router from '@/core/Router';
import { store as coreStore } from './app/core/store';
import { konami } from './boot/konami';
import { setupSentry } from './boot/sentry';
import { setupPlugins } from './boot/plugins';
import { registerServiceWorker } from './boot/registerServiceWorker';

Vue.prototype.$log = console.log;
Vue.prototype.json = (x) => JSON.stringify(x);
Vue.prototype.$location = window.location;
Vue.prototype.appVersion = process.env.VUE_APP_VERSION;

extend('required', { ...required, message: 'The {_field_} is required.' });

Vue.component(
	'DynamicLayout',
	() => import('@/app/core/components/DynamicLayout.vue')
);
Vue.component('Stack', () => import('@/ui/Layout/Stack.vue'));
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component(
	'Panel',
	() => import(/* webpackChunkName: "panel" */ '@/components/Common/Panel.vue')
);
Vue.component(
	'Flag',
	() => import(/* webpackChunkName: "flag" */ '@/components/Common/Flag.vue')
);
Vue.component(
	'Loader',
	() =>
		import(/* webpackChunkName: "loader" */ '@/components/Common/Loader.vue')
);
Vue.component(
	'Spacer',
	() =>
		import(/* webpackChunkName: "spacer" */ '@/components/Common/Spacer.vue')
);
Vue.component(
	'DateTime',
	() =>
		import(/* webpackChunkName: "datetime" */ '@/components/Time/DateTime.vue')
);

Vue.config.productionTip = false;

(async () => {
	// Initialization....
	await coreStore.$init();

	setupPlugins();
	setupSentry();
	konami();

	// Mount the application
	new Vue({
		router,
		render: (h) => h(App),
	}).$mount('#app');

	registerServiceWorker();
})();
