/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-git-commit': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="12" r="4"/><path pid="1" d="M1.05 12H7M17.01 12h5.95"/>',
	},
});
