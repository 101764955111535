/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	beachvolley: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M15 22.1c6.1 0 11.1-5 11.1-11.1S21.1 0 15 0 3.9 5 3.9 11.1s5 11 11.1 11zm-8-5.5c4.2-1.1 7.2-3.8 8.2-4.7.8.2 2.6.9 4.5 2.1-1.2 1.3-4.6 4.5-9.4 5.7-1.3-.8-2.4-1.9-3.3-3.1zm3.1-13.9c1.2-.7 2.6-1.2 4.1-1.3-1.1 4.2-.3 8.2.1 9.5-.5.5-1.8 1.6-3.6 2.7-.6-1.7-1.9-6.2-.6-10.9zM15 20.8c-1.3 0-2.6-.3-3.7-.7 4.7-1.4 7.9-4.6 9-5.8.9.6 1.9 1.4 2.7 2.3-1.7 2.5-4.7 4.2-8 4.2zm8.7-5.5c-3.1-3.1-6.9-4.4-8.2-4.8-.2-.8-.6-2.7-.6-5 1.7.4 6.2 1.8 9.7 5.4v.2c.1 1.5-.2 2.9-.9 4.2zm.9-5.4c-3.6-3.4-8-4.7-9.6-5.1.1-1.1.2-2.3.6-3.5 4.7.4 8.5 4 9 8.6zM9.2 3.3c-1 4.8.2 9 .8 10.6-1.1.6-2.4 1.1-3.8 1.5-.6-1.3-1-2.7-1-4.3.1-3.2 1.6-6.1 4-7.8zM26.5 25c-.3-.3-.7-.3-1 0-.3.3-.9.3-1.2 0l-1.1-1.1c-.9-.9-2.3-.9-3.1 0L18.9 25c-.3.3-.9.3-1.2 0l-1.1-1.1c-.9-.9-2.3-.9-3.1 0L12.3 25c-.3.3-.9.3-1.2 0L10 23.9c-.9-.9-2.3-.9-3.1 0L5.7 25c-.3.3-.9.3-1.2 0-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1 .4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7l1.1-1.1c.3-.3.9-.3 1.2 0l1.1 1.1c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7l1.1-1.1c.3-.3.9-.3 1.2 0l1.1 1.1c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7l1.1-1.1c.3-.3.9-.3 1.2 0l1.1 1.1c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7.2-.3.2-.8 0-1z"/>',
	},
});
