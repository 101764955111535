/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-corner-left-down': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M14 15l-5 5-5-5"/><path pid="1" d="M20 4h-7a4 4 0 00-4 4v12"/>',
	},
});
