/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	pen: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M26 4.2l-1.9-1.9c-.8-.8-2.1-.8-2.9 0l-.9.9-6.6 6.6-7.9 7.9-.1.1-.1.1-2.1 5.9c-.1.3 0 .5.2.7.1.1.3.2.5.2h.2l5.9-2.1c.1 0 .1 0 .1-.1 0 0 .1 0 .1-.1l9.2-9.2L25.1 8l.9-.8c.8-.8.8-2.2 0-3zm-3.9-.9c.3-.3.7-.3.9 0l2 1.9c.3.3.3.7 0 .9l-.4.4-2.8-2.8.3-.4zM5.3 23l1.2-3.5 2.3 2.3L5.3 23zm12.1-9.2l-7.3 7.3-2.8-2.8L16 9.5l4.8-4.8L22 5.9l-5.3 5.3-1 1-.7.6-5.7 5.7c-.1.1-.1.4 0 .5.1.1.2.1.2.1.1 0 .2 0 .2-.1l6.4-6.4 6.2-6.2 1.2 1.2-6.1 6.2z"/>',
	},
});
