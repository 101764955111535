/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-image': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="3" y="3" width="18" height="18" rx="2" ry="2"/><circle pid="1" cx="8.5" cy="8.5" r="1.5"/><path pid="2" d="M21 15l-5-5L5 21"/>',
	},
});
