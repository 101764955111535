























import { defineComponent, ref } from '@vue/composition-api';

const App = defineComponent({
	name: 'App',

	components: {
		IEBanner: () => import('@/app/core/components/IEBanner.vue'),
		UpdateBanner: () => import('@/app/core/components/UpdateBanner.vue'),
	},

	setup(_, ctx) {
		const layout = ref<unknown>('div');
		const layoutProps = ref<Record<string, unknown>>({});

		ctx.root.$on('update:layout', (value: unknown) => (layout.value = value));

		ctx.root.$on(
			'update:layout-props',
			(value: Record<string, unknown>) => (layoutProps.value = value)
		);

		return { layout, layoutProps };
	},
});

export default App;
