/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-server': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="2" y="2" width="20" height="8" rx="2" ry="2"/><rect pid="1" x="2" y="14" width="20" height="8" rx="2" ry="2"/><path pid="2" d="M6 6h0M6 18h0"/>',
	},
});
