/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-share-2': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="18" cy="5" r="3"/><circle pid="1" cx="6" cy="12" r="3"/><circle pid="2" cx="18" cy="19" r="3"/><path pid="3" d="M8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98"/>',
	},
});
