/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-minimize-2': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M4 14h6v6M20 10h-6V4M14 10l7-7M3 21l7-7"/>',
	},
});
