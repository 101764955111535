/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-wind': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M9.59 4.59A2 2 0 1111 8H2m10.59 11.41A2 2 0 1014 16H2m15.73-8.27A2.5 2.5 0 1119.5 12H2"/>',
	},
});
