/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-git-merge': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="18" cy="18" r="3"/><circle pid="1" cx="6" cy="6" r="3"/><path pid="2" d="M6 21V9a9 9 0 009 9"/>',
	},
});
