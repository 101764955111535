/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-scissors': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="6" cy="6" r="3"/><circle pid="1" cx="6" cy="18" r="3"/><path pid="2" d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"/>',
	},
});
