/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-at-sign': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="12" r="4"/><path pid="1" d="M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94"/>',
	},
});
