/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	tools: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M27.5 20.9l-1-.9-6.5-6.6 7.8-7.8c.1-.1.2-.3.2-.5s-.1-.4-.2-.5L23.4.2c-.3-.3-.8-.3-1 0L14.6 8 9.2 2.6s-.1 0-.1-.1L9 2.4 2.7.3c-.3-.1-.6 0-.8.2-.2.1-.2.4-.1.7L4 7.4c0 .1 0 .1.1.2 0 0 0 .1.1.1l5.4 5.4-7.8 7.8c-.3.3-.3.8 0 1l4.4 4.4c.1.1.3.2.5.2s.4-.1.5-.2l7.8-7.8 6.6 6.6.9.9c.4.4 1 .6 1.5.6s1.1-.2 1.5-.6l2-2c.8-.9.8-2.3 0-3.1zM7.3 3.5L5 5.8 3.7 2.2l3.6 1.3zM5.7 7.2l3-3L13.5 9l-1.2 1.2-3.9-3.8c-.1-.2-.4-.2-.5 0-.1.1-.1.4 0 .5l3.9 3.9-1.3 1.2-4.8-4.8zm.9 17.5l-3.3-3.3L22.9 1.8l3.3 3.3-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0s-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0s-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0s-.1.4 0 .5l1.4 1.4-1.5 1.5-1.4-1.4c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l1.4 1.4-1.6 1.6zm12.3-10.2l6 6-1.2 1.2-5-5c-.1-.1-.4-.1-.5 0s-.1.4 0 .5l5 5-1.2 1.3-6-6 2.9-3zm7.5 8.4l-2 2c-.3.3-.7.3-1 0l-.4-.4 3-3 .4.4c.3.3.3.8 0 1z"/>',
	},
});
