export default [
	{
		path: '/media/posts',
		component: () => import('@/app/posts/views/PostListView.vue'),
	},
	{
		path: '/media/posts/create',
		component: () => import('@/views/Media/PostCreateView.vue'),
	},
	{
		path: '/media/posts/:id/translate/:from/:to',
		component: () => import('@/views/Media/PostTranslateView.vue'),
	},
	{
		path: '/media/posts/:id/:language_code',
		component: () => import('@/views/Media/PostEditView.vue'),
	},
];
