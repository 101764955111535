/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-chrome': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="12" r="10"/><circle pid="1" cx="12" cy="12" r="4"/><path pid="2" d="M21.17 8H12M3.95 6.06L8.54 14M10.88 21.94L15.46 14"/>',
	},
});
