import axios from 'axios';

export const http = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/xml',
	},
	withCredentials: true,
});
