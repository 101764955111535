/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	eye: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<g _fill="#1d1d1b"><path pid="0" d="M29.9 12.9C26.8 7.7 21.1 4.4 15 4.4S3.2 7.7.1 13c-.1.2-.1.5 0 .7C3.2 19 8.9 22.3 15 22.3s11.8-3.3 14.9-8.6c.1-.3.1-.5 0-.8zm-7 .4c0 3.2-1.9 6-4.7 7.2-1 .2-2.1.3-3.2.3s-2.2-.1-3.2-.3c-2.7-1.2-4.7-4-4.7-7.2s1.9-6 4.7-7.2c1-.2 2.1-.3 3.2-.3s2.2.1 3.2.3c2.8 1.2 4.7 4 4.7 7.2zm-21.3 0c1.8-3 4.6-5.3 7.8-6.5-1.8 1.6-3 3.9-3 6.5s1.2 4.9 3 6.5c-3.2-1.2-6-3.5-7.8-6.5zm19 6.5c1.8-1.6 3-3.9 3-6.5s-1.2-4.9-3-6.5c3.2 1.2 6 3.5 7.8 6.5-1.8 3-4.6 5.3-7.8 6.5z"/><path pid="1" d="M11.7 13.3c0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3c0-1.8-1.5-3.3-3.3-3.3s-3.3 1.5-3.3 3.3zm5.2 0c0 1-.8 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9 1 0 1.9.9 1.9 1.9z"/></g>',
	},
});
