/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'tip-ul': {
		width: 16,
		height: 16,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="2.5" cy="3.998" r="2.5"/><path pid="1" d="M8.5 5H23a1 1 0 000-2H8.5a1 1 0 000 2z"/><circle pid="2" cx="2.5" cy="11.998" r="2.5"/><path pid="3" d="M23 11H8.5a1 1 0 000 2H23a1 1 0 000-2z"/><circle pid="4" cx="2.5" cy="19.998" r="2.5"/><path pid="5" d="M23 19H8.5a1 1 0 000 2H23a1 1 0 000-2z"/>',
	},
});
