/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	news: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M23.1 8.3h-3.2c-.2 0-.4.2-.4.4s.2.4.4.4h3.2c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM23.1 5h-3.2c-.2 0-.4.2-.4.4s.2.4.4.4h3.2c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM23.1 11.8h-10c-.2 0-.4.2-.4.4s.2.4.4.4h10c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM23.1 15.4h-10c-.2 0-.4.2-.4.4s.2.4.4.4h10c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM23.1 18.9h-10c-.2 0-.4.2-.4.4s.2.4.4.4h10c.2 0 .4-.2.4-.4s-.2-.4-.4-.4z"/><path pid="1" d="M24 .3H12.2C10.2.3 8.5 2 8.5 4v6.1h-3c-2 0-3.7 1.7-3.7 3.7v8.4c0 2.2 1.8 4.1 4.1 4.1H24c2 0 3.7-1.7 3.7-3.7V4c0-2-1.6-3.7-3.7-3.7zM8.5 22.2c0 1.5-1.2 2.7-2.7 2.7-1.5 0-2.7-1.2-2.7-2.7v-8.4c0-1.3 1-2.3 2.3-2.3h3.1v10.7zm17.8.4c0 1.3-1 2.3-2.3 2.3H8.9c.6-.7 1-1.7 1-2.7V4c0-1.3 1-2.3 2.3-2.3H24c1.3 0 2.3 1 2.3 2.3v18.6z"/><path pid="2" d="M13.1 9h4.4c.2 0 .4-.2.4-.4V5.1c0-.2-.2-.4-.4-.4h-4.4c-.2 0-.4.2-.4.4v3.5c.1.3.2.4.4.4zm.4-3.5h3.7v2.8h-3.7V5.5z"/>',
	},
});
