/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'tip-hr': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M5 13a1 1 0 010-2h14a1 1 0 010 2H5z"/>',
	},
});
