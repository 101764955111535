/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	shirt: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M29.8 7L23 .2c-.2-.1-.3-.2-.5-.2h-15c-.1 0-.3.1-.5.2L.2 7c-.3.3-.3.7 0 1l4.1 4.1c.3.3.7.3 1 0l1.6-1.6v15.4c0 .4.3.7.7.7h15c.4 0 .7-.3.7-.7V10.5l1.6 1.6c.3.3.7.3 1 0L29.8 8c.1-.1.2-.3.2-.5s-.1-.3-.2-.5zM18.3 1.4C18 2.9 16.6 4 15 4s-3-1.1-3.3-2.7l6.6.1zM4.8 10.6L1.7 7.5l.9-.9 3.1 3.1-.9.9zM23 8.3c-.2-.2-.5-.3-.8-.2-.3.1-.4.4-.4.6v16.4H8.2V8.8c0-.3-.2-.5-.4-.6-.3-.1-.6-.1-.8.1l-.8.9-3.1-3.1 4.7-4.7H11c.3 1.9 2 3.4 4 3.4s3.7-1.5 4-3.4h3.2l4.7 4.7-3.1 3.1-.8-.9zm2.2 2.3l-.9-.9 3.1-3.1.9.9-3.1 3.1z"/>',
	},
});
