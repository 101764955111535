/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	user: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M15 18.5c-1.5 0-2.9-.3-4.3-.9-.2-.1-.3-.3-.2-.5.1-.2.3-.3.5-.2 2.6 1.1 5.5 1.1 8 0 .2-.1.4 0 .5.2.1.2 0 .4-.2.5-1.4.6-2.8.9-4.3.9m0-17.1c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5m0 14.3c-4.3 0-7.9-3.5-7.9-7.9C7.1 3.5 10.7 0 15 0s7.9 3.5 7.9 7.9c0 4.3-3.6 7.8-7.9 7.8"/><path pid="1" d="M24.1 26.6H5.9c-2.8 0-5-2.2-5-5v-1.2c0-3.3 2-6.4 5-7.8.3-.2.8 0 .9.3.2.3 0 .8-.3.9-2.6 1.2-4.2 3.7-4.2 6.5v1.2c0 2 1.6 3.6 3.6 3.6h18.2c2 0 3.6-1.6 3.6-3.6v-1.2c0-2.8-1.6-5.4-4.2-6.5-.3-.2-.5-.6-.3-.9.2-.3.6-.5.9-.3 3 1.4 5 4.5 5 7.8v1.2c0 2.8-2.2 5-5 5"/>',
	},
});
