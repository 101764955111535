/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-anchor': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="5" r="3"/><path pid="1" d="M12 22V8M5 12H2a10 10 0 0020 0h-3"/>',
	},
});
