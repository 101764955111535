/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	media: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M25.8 3.8h-3.9l-.6-1.1c-.5-.9-1.4-1.5-2.4-1.8-1-.3-2.1-.1-3 .4l-4.3 2.5h-2c-2.1 0-3.9 1.5-4.2 3.6l-3.5 2C0 10.5-.6 12.9.5 14.7l5.2 9c.5.9 1.4 1.5 2.4 1.8.3.1.7.1 1 .1.7 0 1.3-.2 1.9-.5l4.3-2.5h10.4c2.3 0 4.2-1.9 4.2-4.2V8.1c.1-2.4-1.8-4.3-4.1-4.3zm-9.5-1.9c1.5-.9 3.5-.4 4.4 1.2l.4.7H13l3.3-1.9zM6.7 8.1c0-1.6 1.3-2.9 2.9-2.9h16.1c1.6 0 2.9 1.3 2.9 2.9v8.8l-5-5c-.6-.6-1.7-.6-2.3 0l-5 5c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-2.1-2.1c-.3-.3-.7-.5-1.2-.5-.4 0-.9.2-1.2.5l-3.9 3.9V8.1zm4 16.5c-.7.4-1.6.5-2.4.3-.8-.2-1.5-.8-2-1.5l-5.2-9C.2 12.9.7 10.9 2.3 10l3.1-1.8v10.2c0 2.3 1.9 4.2 4.2 4.2H14l-3.3 2zm15.1-3.3H9.6c-1.2 0-2.3-.8-2.7-1.9 0 0 .1 0 .1-.1l4.1-4.1c.2-.2.4-.3.7-.3.3 0 .5.1.7.3l2.1 2.1c.3.3.7.5 1.2.5s.9-.2 1.2-.5l5-5c.2-.2.4-.3.7-.3s.5.1.7.3l5.4 5.4h.1v.6c-.3 1.7-1.5 3-3.1 3z"/><path pid="1" d="M14.6 11.4c1 0 1.9-.8 1.9-1.9 0-1-.8-1.9-1.9-1.9s-1.9.8-1.9 1.9c.1 1.1.9 1.9 1.9 1.9zm0-3c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2.6-1.2 1.2-1.2z"/>',
	},
});
