/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-tv': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="2" y="7" width="20" height="15" rx="2" ry="2"/><path pid="1" d="M17 2l-5 5-5-5"/>',
	},
});
