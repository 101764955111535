import coreRoutes from '@/app/core/routes';
import authRoutes from '@/app/auth/routes';
import mediaRoutes from '@/app/media/routes';
import postRoutes from '@/app/posts/routes';
import adminRoutes from '@/app/admin/routes';
import transferRoutes from '@/app/transfers/routes';
import playerRoutes from '@/app/players/routes';

export default [
	...authRoutes,
	...adminRoutes,
	...mediaRoutes,
	...postRoutes,
	...coreRoutes,
	...transferRoutes,
	...playerRoutes,
];
