/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-tablet': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="4" y="2" width="16" height="20" rx="2" ry="2" transform="rotate(180 12 12)"/><path pid="1" d="M12 18h0"/>',
	},
});
