export default [
	{
		path: '/login',
		component: () => import('@/app/auth/views/LoginView.vue'),
		meta: {
			middleware: ['guest'],
		},
	},
	{
		path: '/forgot-password',
		component: () => import('@/app/auth/views/ForgotPasswordView.vue'),
		meta: {
			middleware: ['guest'],
		},
	},
];
