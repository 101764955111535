export default [
	{
		path: '/players',
		component: () => import('@/app/players/views/PlayerListView.vue'),
	},
	{
		path: '/players/:id',
		component: () => import('@/app/players/views/PlayerView.vue'),
	},
];
