/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-percent': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M19 5L5 19"/><circle pid="1" cx="6.5" cy="6.5" r="2.5"/><circle pid="2" cx="17.5" cy="17.5" r="2.5"/>',
	},
});
