/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-video': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M23 7l-7 5 7 5V7z"/><rect pid="1" x="1" y="5" width="15" height="14" rx="2" ry="2"/>',
	},
});
