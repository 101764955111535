/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'carret-small_right': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M13.2 13.3L9.6 9.7c-.3-.3-.3-.7 0-1 .1-.1.3-.2.4-.2s.4.1.5.2l4.1 4.1c.3.3.3.7 0 1l-4.1 4.1c-.3.3-.7.3-1 0-.3-.3-.3-.7 0-1l3.7-3.6zM15.4 8.7c.1-.1.3-.2.5-.2s.4.1.5.2l4.1 4.1c.3.3.3.7 0 1l-4.1 4.1c-.3.3-.7.3-1 0-.3-.3-.3-.7 0-1l3.6-3.6-3.6-3.6c-.3-.3-.3-.7 0-1z"/>',
	},
});
