/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	calendar: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M23.1 1.8H21V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.1h-3.9V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.1h-3.9V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.1H6.9c-1.2 0-2.3 1-2.3 2.3v20.3c0 1.2 1 2.3 2.3 2.3h16.2c1.2 0 2.3-1 2.3-2.3V4.1c-.1-1.3-1.1-2.3-2.3-2.3zm-11 17.9v-5.1h5.8v5.1h-5.8zm5.8.7v4.8h-5.8v-4.8h5.8zm6.1-6.5h-5.4V8.8H24v5.1zm-6.1 0h-5.8V8.8h5.8v5.1zm-6.5 0H6V8.8h5.4v5.1zm-5.4.7h5.4v5.1H6v-5.1zm12.6 0H24v5.1h-5.4v-5.1zM6.9 3.2H9v.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.9h3.9v.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.9h3.9v.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.9h2.1c.5 0 .9.4.9.9v4.1H6V4.1c0-.5.4-.9.9-.9zM6 24.3v-3.9h5.4v4.8H6.9c-.5 0-.9-.4-.9-.9zm17.1.9h-4.5v-4.8H24v3.9c0 .5-.4.9-.9.9z"/>',
	},
});
