/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-maximize-2': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7"/>',
	},
});
