/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-check-circle': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M22 11.08V12a10 10 0 11-5.93-9.14"/><path pid="1" d="M22 4L12 14.01l-3-3"/>',
	},
});
