/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	menu: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M29.2 7.4H5.7c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h23.5c.4 0 .8.4.8.8s-.4.8-.8.8zM29.2 15H12.7c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h16.5c.4 0 .8.4.8.8s-.4.8-.8.8zM29.2 22.6H.8c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h28.4c.4 0 .8.4.8.8s-.4.8-.8.8z"/>',
	},
});
