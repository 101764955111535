/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'gender-male': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M27.5 0h-9.8c-.4 0-.7.3-.7.7s.3.7.7.7H26l-9.4 9.4c-1.6-1.4-3.7-2.1-5.8-2.1-2.4 0-4.6.9-6.3 2.6-1.7 1.7-2.6 3.9-2.6 6.3s.9 4.6 2.6 6.3c1.7 1.7 3.9 2.6 6.3 2.6s4.6-.9 6.3-2.6c1.7-1.7 2.6-3.9 2.6-6.3 0-2.2-.8-4.2-2.1-5.8l9.2-9.2v8c0 .4.3.7.7.7.4 0 .7-.3.7-.7V.7c0-.4-.3-.7-.7-.7zM16.1 22.9c-1.4 1.4-3.3 2.2-5.3 2.2s-3.9-.8-5.3-2.2c-1.4-1.4-2.2-3.3-2.2-5.3s.8-3.9 2.2-5.3c1.4-1.4 3.3-2.2 5.3-2.2s3.9.8 5.3 2.2c1.4 1.4 2.2 3.3 2.2 5.3s-.8 3.9-2.2 5.3z"/>',
	},
});
