/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-download-cloud': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M8 17l4 4 4-4M12 12v9"/><path pid="1" d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"/>',
	},
});
