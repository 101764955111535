/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-git-pull-request': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="18" cy="18" r="3"/><circle pid="1" cx="6" cy="6" r="3"/><path pid="2" d="M13 6h3a2 2 0 012 2v7M6 9v12"/>',
	},
});
