/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-monitor': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="2" y="3" width="20" height="14" rx="2" ry="2"/><path pid="1" d="M8 21h8M12 17v4"/>',
	},
});
