/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	loader: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M1.9 13.4c0 .1 0 .1 0 0 0 1.8.4 3.6 1.2 5.2.6 1.6 1.7 3 2.9 4.2 1.2 1.2 2.7 2.2 4.3 2.8s3.3.9 5 .9c1.8 0 3.4-.4 5-1.1 1.5-.7 2.9-1.7 4.1-2.9 1.2-1.2 2.1-2.6 2.7-4.2s.9-3.2.9-4.9c-.1 1.6-.4 3.3-1.1 4.8-.7 1.5-1.6 2.9-2.8 4s-2.6 2-4.1 2.6-3.1.9-4.7.9-3.2-.4-4.7-1-2.8-1.6-3.9-2.7c-1.1-1.2-2-2.5-2.6-4-.5-1.5-.8-3-.8-4.6 0-6.6 5.4-12 12-12 .4 0 .7-.3.7-.7s-.3-.7-.7-.7C7.9 0 1.9 6 1.9 13.4z"/>',
	},
});
