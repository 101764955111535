/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	conditions: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M22.2 1.8h-1.7V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.1h-3.4V.7c0-.4-.3-.7-.7-.7s-.7.3-.7.7v1.1h-3.4V.7c0-.4-.3-.7-.7-.7-.3 0-.6.3-.6.7v1.1H7.8c-1.2 0-2.2 1-2.2 2.2v20.3c0 1.2 1 2.2 2.2 2.2h14.3c1.2 0 2.2-1 2.2-2.2V4c.1-1.2-.9-2.2-2.1-2.2zm.9 22.6c0 .5-.4.9-.9.9H7.8c-.5 0-.9-.4-.9-.9V4c0-.5.4-.9.9-.9h1.7V4c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.8h3.4v.9c0 .4.3.7.7.7s.7-.3.7-.7v-.9h3.4v.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.9h1.7c.5 0 .9.4.9.9v20.3z"/><path pid="1" d="M19.8 8.1h-9.5c-.2 0-.3.2-.3.3 0 .2.2.3.3.3h9.5c.2 0 .3-.2.3-.3 0-.1-.1-.3-.3-.3zM19.8 11.5h-9.5c-.2 0-.3.2-.3.3s.2.3.3.3h9.5c.2 0 .3-.2.3-.3s-.1-.3-.3-.3zM19.8 14.8h-9.5c-.2 0-.3.2-.3.3 0 .2.2.3.3.3h9.5c.2 0 .3-.2.3-.3 0-.1-.1-.3-.3-.3zM19.8 18.2h-9.5c-.2 0-.3.2-.3.3s.2.3.3.3h9.5c.2 0 .3-.2.3-.3s-.1-.3-.3-.3z"/>',
	},
});
