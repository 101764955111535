/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-cloud-lightning': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M19 16.9A5 5 0 0018 7h-1.26a8 8 0 10-11.62 9"/><path pid="1" d="M13 11l-4 6h6l-4 6"/>',
	},
});
