/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'gender-female': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M23.6 8.6C23.6 3.8 19.7 0 15 0c-4.7 0-8.6 3.8-8.6 8.6 0 4.5 3.5 8.2 7.9 8.5v3.4h-4c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h4v4c0 .4.3.7.7.7s.7-.3.7-.7v-4h4c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-4v-3.4c4.4-.3 7.9-4 7.9-8.5zm-15.8 0c0-4 3.2-7.2 7.2-7.2s7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2-7.2-3.2-7.2-7.2z"/>',
	},
});
