/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	carret: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M22 11.9L10.8.6c-.8-.8-2.2-.8-3 0-.8.8-.8 2.2 0 3l9.7 9.7L7.9 23c-.8.8-.8 2.2 0 3 .4.4 1 .6 1.5.6s1.1-.2 1.5-.6L22 14.8c.8-.8.8-2.1 0-2.9z"/>',
	},
});
