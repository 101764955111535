/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-codepen': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M12 2l10 6.5v7L12 22 2 15.5v-7L12 2zM12 22v-6.5"/><path pid="1" d="M22 8.5l-10 7-10-7"/><path pid="2" d="M2 15.5l10-7 10 7M12 2v6.5"/>',
	},
});
