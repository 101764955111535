import * as z from 'zod';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { http } from '@/app/core/utils/http2';
import { store as coreStore } from '@/app/core/store';

const getCurrentUserQuery = '<Request Type="GetUser" />';
const getUserAccessesQuery =
	'<Request Type="LogIn" WantParameters="True" WantAccesses="True" />';

export interface State {
	currentUser: Record<string, any>;
	accesses: UserAccesses;
}

const state: State = {
	currentUser: null,
	accesses: null,
};

export const store = {
	state,

	/**
	 * Initialize the state when booting the application.
	 */
	async $init() {
		const {
			data: { status },
		} = await this.validateSession();

		if (status === 'unauthenticated') {
			return;
		}

		await this.hasBeenAuthenticated();
	},

	validateSession() {
		return http.post('/auth/check');
	},

	async login({ username, password }) {
		await http.post(
			'/auth/login',
			{ username, password },
			{
				headers: { 'Content-Type': 'application/json' },
			}
		);

		await this.hasBeenAuthenticated();
	},

	logout() {
		return http.delete('/auth/logout');
	},

	resetPassword(username: string) {
		return http.post(
			'/auth/reset-password',
			{ username },
			{
				headers: { 'Content-Type': 'application/json' },
			}
		);
	},

	async hasBeenAuthenticated() {
		await Promise.all([this.getCurrentUser(), this.getUserAccesses()]);

		coreStore.hasBeenAuthenticated();
	},

	async getCurrentUser() {
		const {
			data: { data },
		} = await http.post('/', getCurrentUserQuery);

		this.state.currentUser = data;

		if (process.env.NODE_ENV === 'production') {
			LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY);

			LogRocket.getSessionURL((sessionUrl) => {
				Sentry.configureScope((scope) => {
					scope.setExtra('sessionUrl', sessionUrl);
				});
			});

			Sentry.setUser({
				id: String(data.no),
				username: data.username,
				email: data.eMailAddress,
			});

			LogRocket.identify(String(data.no), {
				email: data.eMailAddress,
				name: data.username,

				...data,
			});
		}
	},

	async getUserAccesses() {
		const {
			data: { data },
		} = await http.post('/', getUserAccessesQuery);

		const accesses = userAccessesSchema.parse(data.accesses);
		const normalizedAccesses = normalizeAcceses(accesses);
		this.state.accesses = normalizedAccessesSchema.parse(normalizedAccesses);
	},
};

const conditionSchema = z.object({
	operator: z.string(),
	propertyName: z.string(),
	value: z.string(),
});

const permissionSchema = z.object({
	conditions: z.array(conditionSchema).optional(),
	name: z.string(),
});

const authorizationSchema = z.object({
	fields: z.array(permissionSchema).optional(),
	permissions: z.array(permissionSchema).optional(),
});

const userAccessSchema = z.object({
	dataType: z.string(),
	authorizations: z.object({
		view: authorizationSchema.optional(),
		change: authorizationSchema.optional(),
		insert: authorizationSchema.optional(),
	}),
});

const userAccessesSchema = z.array(userAccessSchema);

const normalizedAccessSchema = z.object({
	dataType: z.string(),
	authorizations: z.object({
		view: z.array(permissionSchema).optional(),
		change: z.array(permissionSchema).optional(),
		insert: z.array(permissionSchema).optional(),
	}),
	permissions: z.array(permissionSchema),
});

const normalizedAccessesSchema = z.array(normalizedAccessSchema);

function normalizeAcceses(accesses: z.infer<typeof userAccessesSchema>) {
	return accesses.reduce((acc, access) => {
		const extractedData = Object.keys(access.authorizations).reduce(
			(acc, value) => {
				if (access.authorizations[value].permissions) {
					const permissions = access.authorizations[value]?.permissions || [];
					acc.permissions.push(...permissions);
				}

				if (access.authorizations[value].fields) {
					if (!acc.authorizations[value]) {
						acc.authorizations[value] = [];
					}

					acc.authorizations[value].push(
						...access.authorizations[value].fields
					);
				}

				return acc;
			},
			{ authorizations: {}, permissions: [] }
		);

		acc.push({
			...extractedData,
			dataType: access.dataType,
		});

		return acc;
	}, [] as UserAccess[]);
}

export type UserAccesses = z.infer<typeof normalizedAccessesSchema>;
export type UserAccess = z.infer<typeof normalizedAccessSchema>;
