import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/routes';
import { store } from '@/app/auth/store';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, _, next) => {
	const hasGuestMiddleware = to.matched.some((r) =>
		r.meta?.middleware?.includes('guest')
	);
	const isLoggedIn = store.state.currentUser !== null;

	if (!hasGuestMiddleware && !isLoggedIn) {
		if (to.path !== '/') {
			return next({ path: `/login?redirect=${to.path}` });
		}

		return next({ path: '/login' });
	}

	if (hasGuestMiddleware && isLoggedIn) {
		return next({ path: '/' });
	}

	next();
});

export default router;
