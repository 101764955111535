/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-upload-cloud': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M16 16l-4-4-4 4M12 12v9"/><path pid="1" d="M20.39 18.39A5 5 0 0018 9h-1.26A8 8 0 103 16.3"/><path pid="2" d="M16 16l-4-4-4 4"/>',
	},
});
