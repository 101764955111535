/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	family: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M17.1 15.2c-1.4.6-2.9.6-4.3 0-.1-.1-.3 0-.3.1-.1.1 0 .3.1.3.7.3 1.5.5 2.3.5s1.6-.2 2.3-.5c.1-.1.2-.2.1-.3.1-.1 0-.1-.2-.1zM15 14.7c2.4 0 4.4-2 4.4-4.4C19.3 8 17.4 6 15 6s-4.4 2-4.4 4.4c0 2.4 2 4.3 4.4 4.3zM15 7c1.9 0 3.4 1.5 3.4 3.4s-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4S13.1 7 15 7z"/><path pid="1" d="M19.9 12.8c-.2-.1-.5 0-.7.2-.1.2 0 .5.2.7 1.3.6 2.2 1.9 2.2 3.4v.6c0 1-.8 1.8-1.8 1.8H10c-1 0-1.8-.8-1.8-1.8v-.6c0-1.5.9-2.8 2.2-3.4.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-1.7.8-2.8 2.5-2.8 4.3v.6c0 1.5 1.3 2.8 2.8 2.8h9.8c1.5 0 2.8-1.3 2.8-2.8v-.6c.2-1.8-.9-3.5-2.6-4.3zM26.2 15c.1-.1.2-.2.1-.3-.1-.1-.2-.2-.3-.1-.9.4-2 .4-2.9 0-.1-.1-.3 0-.3.1-.1.1 0 .3.1.3.5.2 1.1.3 1.6.3.6.1 1.1 0 1.7-.3zM24.5 14.4c1.7 0 3.2-1.4 3.2-3.2S26.3 8 24.5 8c-1.7 0-3.2 1.4-3.2 3.2s1.5 3.2 3.2 3.2zm0-5.3c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2z"/><path pid="2" d="M28 12.8c-.2-.1-.5 0-.7.2-.1.2 0 .5.2.7.9.4 1.5 1.3 1.5 2.3v.4c0 .6-.5 1.1-1.1 1.1h-3.3c-.3 0-.5.2-.5.5s.2.5.5.5h3.3c1.2 0 2.1-.9 2.1-2.1V16c0-1.4-.8-2.6-2-3.2zM6.9 14.6C6 15 5 15 4 14.6c-.1-.1-.3 0-.3.1-.1.1 0 .3.1.3.5.2 1.1.3 1.6.3s1.1-.1 1.6-.3c.1-.1.2-.2.1-.3.1-.1 0-.2-.2-.1zM5.5 14.4c1.7 0 3.2-1.4 3.2-3.2S7.2 8.1 5.5 8.1c-1.7 0-3.2 1.4-3.2 3.2s1.4 3.1 3.2 3.1zm0-5.3c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2z"/><path pid="3" d="M5.4 17.5H2.1c-.6 0-1.1-.5-1.1-1.1V16c0-1 .6-1.8 1.4-2.2.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-1.2.6-2 1.8-2 3.1v.4c0 1.2.9 2.1 2.1 2.1h3.3c.3 0 .5-.2.5-.5.1-.3-.1-.5-.4-.5z"/>',
	},
});
