/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	trophy: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M20.7 15c7.5-.5 7.6-8.9 7.6-9 0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-4.3V.7c.1-.4-.2-.7-.6-.7H7.3c-.4 0-.7.3-.7.7v4.9H2c-.1 0-.2 0-.3.1 0 .2-.1.3-.1.4 0 .1.2 8.7 7.9 9 1.3 1.1 3 1.9 4.9 2.1v8h-4.1c-.4 0-.7.3-.7.7 0 .4.3.7.7.7H20c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-4.1v-8c1.8-.3 3.5-1 4.8-2.2zm6.9-8.6c-.1.7-.3 2.3-1 3.8-1 2.2-2.7 3.6-5.1 4 1.3-1.5 2.1-3.5 2.1-5.6V6.4h4zM8 1.5h14.2v7.1c0 3.9-3.2 7.1-7.1 7.1S8 12.5 8 8.6V1.5zm-4.6 8.7c-.7-1.6-.9-3.1-1-3.8h4.2v2.2c0 2.2.8 4.2 2.2 5.7-2.5-.4-4.3-1.7-5.4-4.1z"/><path pid="1" d="M20.4 3.6c0-.2-.2-.4-.4-.4H10c-.2 0-.4.2-.4.4s.2.4.4.4h10c.2 0 .4-.2.4-.4z"/>',
	},
});
