/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	flag: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M29.5 18.2c-.1-.7-.5-1.3-1.1-1.6-1.1-.6-2.3-1.5-3.5-2.7-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1 .3.3.5.5.8.7l-7.1 7.1c-.3-.2-.5-.5-.8-.7-1.9-1.9-2.9-3.7-3.2-4.5l3.9 1.2h.4l.1-.1 8.1-8.1c1.4-1.4-1.4-5-3.2-6.9-1.3-1.3-2.6-2.3-3.8-3-1.4-.8-2.5-.9-3.1-.3L8.2 8.5s-.1.1-.1.2c-.1-.1-.2 0-.2 0L.5 16.1c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2l7.4-7.4s.1-.1.1-.2l.1-.1c.5-.1 2.5.8 4.9 3.2 1.9 1.9 2.9 3.7 3.2 4.5l-3.9-1.2h-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1c-.6.6-.4 1.6.3 3 .6 1.2 1.7 2.5 3 3.8 1.5 1.5 4.1 3.6 5.8 3.6.4 0 .8-.1 1-.4l6.1-6.1c.8-.2 1.1-.9 1-1.6zm-4.2-8.7l-6.9 6.9c-.5-1.5-2-3.2-3.1-4.5l7.1-7.1c2.2 2.4 3.1 4.3 2.9 4.7zm-14-.8c-.3-.2-.7-.3-1-.5l6.9-6.9c.4-.1 2.4.8 4.7 3l-7.1 7.1c-1.2-1.1-2.4-2.1-3.5-2.7zm16.6 10.4l-6.1 6.1c-.4.1-1.8-.5-3.6-2l7.1-7.1c.8.7 1.7 1.3 2.5 1.7.2.1.4.3.4.6-.1.2-.1.4-.3.7z"/>',
	},
});
