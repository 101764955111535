/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	volleyball: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M15 24.2c6.7 0 12.1-5.4 12.1-12.1S21.7 0 15 0 2.9 5.4 2.9 12.1 8.3 24.2 15 24.2zM4.4 13.7c2.5 3.4 6.3 3.9 8.3 3.9h.3c-.8 1.3-2 2.6-3.9 3.4-2.5-1.6-4.2-4.2-4.7-7.3zm6.2-3.1c-.1-1.4-.1-6.7 4.9-9.2 1.4.1 2.7.4 3.9.9-4.5 2.4-5.1 7.1-5.1 9-.8.1-2.2-.1-3.7-.7zm14.1 5.9c-2.4-4.5-7.1-5.1-9-5.1 0-.8.2-2.4.9-3.9 1.5-.1 6.7 0 9.1 5-.1 1.4-.4 2.8-1 4zm-9-3.7c.8 0 2.1.2 3.6.7.1 1 .4 6.6-4.7 9.3-1.4-.1-2.8-.4-4-.9 4.5-2.5 5.1-7.2 5.1-9.1zm.3 10c3.9-2.8 4.1-7.3 4-8.9 1.5.7 3 2 3.9 4.2-1.7 2.5-4.6 4.3-7.9 4.7zm9.6-11.9c-2.6-3.8-6.8-4.2-8.7-4.2.8-1.4 2-2.7 4-3.5 2.6 1.7 4.4 4.5 4.7 7.7zM14 1.5c-3.9 2.6-4.2 7-4.2 8.8-1.4-.8-2.8-2-3.7-4.1 1.8-2.6 4.6-4.4 7.9-4.7zM5.3 7.7c2.5 4.5 7.2 5.1 9 5.1 0 .9-.2 2.4-.9 4-1.4.2-6.6.2-9.1-4.6v-.1c0-1.6.3-3.1 1-4.4zM26.4 25.2H3.6c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h22.8c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z"/>',
	},
});
