/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-speaker': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="4" y="2" width="16" height="20" rx="2" ry="2"/><circle pid="1" cx="12" cy="14" r="4"/><path pid="2" d="M12 6h0"/>',
	},
});
