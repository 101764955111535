/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	transfer: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M17.2 10.4c0 .4.3.7.7.7s.7-.3.7-.7V.7c0-.4-.3-.7-.7-.7H8.2c-.4 0-.7.3-.7.7s.3.7.7.7h8L1.9 15.7c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2L17.2 2.4v8zM28.1 9.9c-.3-.3-.7-.3-1 0L12.8 24.2v-8c0-.4-.3-.7-.7-.7s-.7.3-.7.7v9.7c0 .4.3.7.7.7h9.7c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-8l14.3-14.3c.3-.3.3-.7 0-1z"/>',
	},
});
