/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'carret-left': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M7.8 14.8L18.9 26c.4.4 1 .6 1.5.6s1.1-.2 1.5-.6c.8-.8.8-2.2 0-3l-9.6-9.7L22 3.6c.8-.8.8-2.2 0-3-.8-.8-2.2-.8-3 0L7.8 11.9c-.8.8-.8 2.1 0 2.9z"/>',
	},
});
