import { http } from '@/app/core/utils/http2';
import { store as authStore } from '@/app/auth/store';

const getVolleyTournaments = `
	<Request Type="GetVolleyTournamentList" Fields="City Code CountryCode EndDate Gender Name No NoEvent Season ShortNameOrName StartDate Status Type">
		<Filter Types="1 2 3 4 5 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21" />
	</Request>
`;

const getBeachTournaments = `
	<Request Type="GetBeachTournamentList" Fields="City Code CountryCode EndDate Gender Name No NoEvent Season ShortNameOrName StartDate Status Type">
		<Filter Types="0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 36 37 38 39 40 41 42 43 44 45 46 47 48" />
	</Request>
`;

export const store = {
	state: {
		debug: false,
		volleyTournaments: null,
		beachTournaments: null,
		events: null,
	},

	async $init() {
		await authStore.$init();
	},

	hasBeenAuthenticated() {
		http
			.post('/', getVolleyTournaments)
			.then(({ data: { data } }) => (this.state.volleyTournaments = data));

		http
			.post('/', getBeachTournaments)
			.then(({ data: { data } }) => (this.state.beachTournaments = data));
	},
};
