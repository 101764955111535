/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-volume-x': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M11 5L6 9H2v6h4l5 4V5zM23 9l-6 6M17 9l6 6"/>',
	},
});
