/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-award': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="8" r="7"/><path pid="1" d="M8.21 13.89L7 23l5-3 5 3-1.21-9.12"/>',
	},
});
