/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-truck': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M1 3h15v13H1zM16 8h4l3 3v5h-7V8z"/><circle pid="1" cx="5.5" cy="18.5" r="2.5"/><circle pid="2" cx="18.5" cy="18.5" r="2.5"/>',
	},
});
