/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-cloud': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M18 10h-1.26A8 8 0 109 20h9a5 5 0 000-10z"/>',
	},
});
