/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	article: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M5.7 12.5h2.2c.2 0 .3-.2.3-.3s-.2-.3-.3-.3H5.7c-.2 0-.3.2-.3.3s.1.3.3.3zM5.7 16h2.2c.2 0 .3-.2.3-.3 0-.2-.2-.3-.3-.3H5.7c-.2 0-.3.2-.3.3-.1.2.1.3.3.3zM5.7 19.5H10c.2 0 .3-.2.3-.3s-.2-.3-.3-.3H5.7c-.2 0-.3.2-.3.3s.1.3.3.3z"/><path pid="1" d="M19.5 21.8c-.4 0-.7.3-.7.7 0 1.3-1 2.3-2.3 2.3H4.8c-1.3 0-2.3-1-2.3-2.3V4.1c0-1.3 1-2.3 2.3-2.3h11.7c1.3 0 2.3 1 2.3 2.3 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-2-1.6-3.7-3.7-3.7H4.8C2.8.4 1.1 2 1.1 4.1v18.5c0 2 1.6 3.7 3.7 3.7h11.7c2 0 3.7-1.6 3.7-3.7 0-.5-.3-.8-.7-.8z"/><path pid="2" d="M10.4 5.2c0-.2-.2-.3-.3-.3H5.7c-.2-.1-.4.1-.4.3v3.5c0 .2.2.3.4.3H10c.2 0 .3-.2.3-.3V5.2zm-.7 3.1H6V5.5h3.7v2.8zM28.7 22.8l-5-5c1.1-1.3 1.6-2.9 1.6-4.6 0-1.9-.7-3.7-2.1-5.1-2.8-2.8-7.4-2.8-10.2 0-1.4 1.4-2.1 3.2-2.1 5.1 0 1.9.7 3.7 2.1 5.1 1.4 1.4 3.2 2.1 5.1 2.1 1.6 0 3.2-.5 4.6-1.6l5 5c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.7 0-1zm-14.6-5.5c-1.1-1.1-1.7-2.5-1.7-4.1 0-1.5.6-3 1.7-4.1 1.1-1.1 2.5-1.7 4.1-1.7 1.5 0 3 .6 4.1 1.7 1.1 1.1 1.7 2.5 1.7 4.1 0 1.5-.6 3-1.7 4.1-1.1 1.1-2.5 1.7-4.1 1.7-1.6 0-3-.6-4.1-1.7z"/><path pid="3" d="M17.9 8.8c-1.2 0-2.2.5-3.1 1.3-.8.8-1.3 1.9-1.3 3.1s.5 2.2 1.3 3.1c.1.1.2.1.2.1.1 0 .2 0 .2-.1.1-.1.1-.4 0-.5-.7-.7-1.1-1.6-1.1-2.6s.4-1.9 1.1-2.6c.7-.7 1.6-1.1 2.6-1.1.2 0 .3-.2.3-.3.1-.3 0-.4-.2-.4z"/>',
	},
});
