/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	payments: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M25.8 3h-3.9l-.6-1.1C20.8 1 19.9.4 18.9.1c-1-.3-2.1-.1-3 .4L11.6 3h-2C7.5 3 5.8 4.6 5.4 6.6l-3.5 2C0 9.7-.6 12.1.5 13.9l5.2 9c.5.9 1.4 1.5 2.4 1.8.3.1.7.1 1 .1.7 0 1.3-.2 1.9-.5l4.3-2.5h10.4c2.3 0 4.2-1.9 4.2-4.2V7.2C30 4.9 28.1 3 25.8 3zM6.7 14.5h21.9v2.8H6.7v-2.8zm9.6-13.4c.7-.4 1.6-.5 2.4-.3.8.2 1.5.8 2 1.5l.4.7H13l3.3-1.9zM9.6 4.4h16.2c1.6 0 2.9 1.3 2.9 2.9v6.6h-22V7.2c0-1.6 1.3-2.8 2.9-2.8zm1.1 19.3c-.7.4-1.6.5-2.4.3-.8-.2-1.5-.8-2-1.5l-5.2-9C.2 12 .7 10.1 2.3 9.2l3.1-1.8v10.2c0 2.3 1.9 4.2 4.2 4.2H14l-3.3 1.9zm15.1-3.2H9.6c-1.5 0-2.7-1.1-2.9-2.6h21.9c-.1 1.5-1.4 2.6-2.8 2.6z"/>',
	},
});
