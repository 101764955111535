/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-paperclip': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48"/>',
	},
});
