/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	plus: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M2.9 14.5H27.1c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2H2.9c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2z"/><path pid="1" d="M16.2 25.4V1.2C16.2.5 15.7 0 15 0s-1.2.5-1.2 1.2V25.4c0 .7.5 1.2 1.2 1.2s1.2-.6 1.2-1.2z"/>',
	},
});
