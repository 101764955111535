/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'volleyball-ball': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M15 0C7.7 0 1.7 6 1.7 13.3c0 7.3 6 13.3 13.3 13.3s13.3-6 13.3-13.3C28.3 6 22.3 0 15 0zm10.8 18.3c-2.7-5.1-8-5.7-10.1-5.7 0-.9.2-2.6 1-4.4 1.6-.1 7.5-.1 10.2 5.6-.1 1.6-.5 3.1-1.1 4.5zm-11.3 6.9c-1.6-.1-3.2-.5-4.5-1.1 5-2.7 5.7-8.1 5.7-10.1.8 0 2.4.2 4 .8.1.9.6 7.4-5.2 10.4zM4.2 8.3c2.7 5.1 8.1 5.7 10.1 5.7 0 .9-.2 2.7-1 4.6-1.4.1-7.3.2-10.2-5.3 0-1.8.4-3.5 1.1-5zm11.4-6.9c1.6.1 3.1.5 4.4 1.1-5.1 2.7-5.7 8-5.7 10.1-.9 0-2.5-.2-4.2-.9-.1-1.4-.2-7.5 5.5-10.3zm11.2 10.8C24 7.7 19.1 7.4 17 7.4c.9-1.6 2.3-3.1 4.5-4 3 1.9 5 5.1 5.3 8.8zM14.1 1.5c-4.6 2.9-4.9 8-4.8 9.9C7.7 10.6 6 9.1 5 6.8c2.1-3 5.4-5.1 9.1-5.3zM3.2 14.9c2.7 3.9 7 4.5 9.2 4.5h.5c-.9 1.5-2.2 3-4.5 3.9-2.8-1.9-4.7-5-5.2-8.4zm12.7 10.2c4.6-3.1 4.7-8.3 4.5-10 1.7.8 3.4 2.3 4.4 4.7-1.9 3-5.1 5-8.9 5.3z" _fill="#1d1d1b"/>',
	},
});
