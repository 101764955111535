/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'carret-small': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M16.8 13.3l3.6-3.6c.3-.3.3-.7 0-1-.1-.1-.3-.2-.5-.2s-.4.1-.5.2l-4.1 4.1c-.3.3-.3.7 0 1l4.1 4.1c.3.3.7.3 1 0 .3-.3.3-.7 0-1l-3.6-3.6zM14.6 8.7c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2l-4.1 4.1c-.3.3-.3.7 0 1l4.1 4.1c.3.3.7.3 1 0 .3-.3.3-.7 0-1L11 13.3l3.6-3.6c.3-.3.3-.7 0-1z"/>',
	},
});
