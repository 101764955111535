import { uid } from 'uid';
import Notification from './Notification.vue';
import Notifications from './Notifications.vue';

interface NotificationOptions {
	type: 'success' | 'danger' | 'warning';
}

const NotificationPlugin = {
	install(Vue) {
		Vue.NotificationStore = Vue.observable({
			state: [],

			add(notification) {
				this.state.push(notification);
			},

			remove(notification) {
				const index = this.state.indexOf(notification);
				this.state.splice(index, 1);
			},
		});

		Vue.component('Notifications', Notifications);
		Vue.component('Notification', Notification);

		Vue.prototype.$notify = function (
			message: string,
			options: NotificationOptions
		) {
			Vue.NotificationStore.add({ id: uid(), message, ...options });
		};
	},
};

export default NotificationPlugin;
