/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-git-branch': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M6 3v12"/><circle pid="1" cx="18" cy="6" r="3"/><circle pid="2" cx="6" cy="18" r="3"/><path pid="3" d="M18 9a9 9 0 01-9 9"/>',
	},
});
