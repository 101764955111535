/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	logout: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M27.9 0c-.4 0-.7.3-.7.7v25.1c0 .4.3.7.7.7s.7-.3.7-.7V.7c0-.4-.4-.7-.7-.7zM16.8 6.4c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l5.3 5.3h-19c-.4 0-.7.3-.7.7 0 .4.3.7.7.7H21l-5.3 5.3c-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2l6.4-6.4c.3-.3.3-.7 0-1l-6.3-6.6z"/>',
	},
});
