/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-shopping-cart': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="9" cy="21" r="1"/><circle pid="1" cx="20" cy="21" r="1"/><path pid="2" d="M1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6"/>',
	},
});
