/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-sidebar': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="3" y="3" width="18" height="18" rx="2" ry="2"/><path pid="1" d="M9 3v18"/>',
	},
});
