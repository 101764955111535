/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-corner-down-left': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M9 10l-5 5 5 5"/><path pid="1" d="M20 4v7a4 4 0 01-4 4H4"/>',
	},
});
