/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'carret-up': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M13.4 6.2L2.2 17.3c-.4.4-.6 1-.6 1.5s.2 1.1.6 1.5c.8.8 2.2.8 3 0l9.7-9.6 9.7 9.7c.8.8 2.2.8 3 0 .8-.8.8-2.2 0-3L16.3 6.2c-.8-.8-2.1-.8-2.9 0z"/>',
	},
});
