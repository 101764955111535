/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-user-x': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"/><circle pid="1" cx="8.5" cy="7" r="4"/><path pid="2" d="M18 8l5 5M23 8l-5 5"/>',
	},
});
