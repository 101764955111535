/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-database': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<ellipse pid="0" cx="12" cy="5" rx="9" ry="3"/><path pid="1" d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"/><path pid="2" d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"/>',
	},
});
