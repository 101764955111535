/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	education: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M29.7 9.4L15.3 1.9c-.2-.1-.4-.1-.6 0L.3 9.4c-.2.1-.3.3-.3.6 0 .2.1.4.3.6l6 3.1V17.5c0 4.4 3.4 7.3 8.7 7.3 5.3 0 8.7-2.9 8.7-7.3v-3.7-.1l2.2-1.1v6.5c-.9.1-1.6.9-1.6 1.9 0 1 .9 1.9 1.9 1.9 1 0 1.9-.9 1.9-1.9 0-.9-.7-1.7-1.6-1.9v-6.8l3.2-1.7c.2-.1.3-.3.3-.6s-.1-.5-.3-.6zm-2.3 11.5c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.8 0 1.3.6 1.3 1.3zm-5-3.4c0 2.9-1.9 6.1-7.4 6.1s-7.4-3.1-7.4-6.1v-3.1l7.1 3.7c.1 0 .2.1.3.1.1 0 .2 0 .3-.1l7.1-3.7v3.1zm-7.4-.7L2 10l13-6.8L28 10l-13 6.8z"/><path pid="1" d="M13.8 5.6L5.4 9.8c-.2.1-.2.3-.1.4.1.1.2.2.3.2h.1l8.4-4.3c.2-.1.2-.3.1-.4-.1-.1-.2-.2-.4-.1z"/>',
	},
});
