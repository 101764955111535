/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-framer': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"/>',
	},
});
