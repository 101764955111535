/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-lock': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="3" y="11" width="18" height="11" rx="2" ry="2"/><path pid="1" d="M7 11V7a5 5 0 0110 0v4"/>',
	},
});
