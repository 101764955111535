/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-voicemail': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="5.5" cy="11.5" r="4.5"/><circle pid="1" cx="18.5" cy="11.5" r="4.5"/><path pid="2" d="M5.5 16h13"/>',
	},
});
