/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-italic': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M19 4h-9M14 20H5M15 4L9 20"/>',
	},
});
