/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	documents: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M28 15.2c0-.5-.2-1-.5-1.3l-3.1-3.2V7.8c0-.2-.1-.4-.2-.5l-5.5-5.5c-.1-.1-.2-.2-.4-.2h-2.7l-1-1c-.3-.4-.8-.6-1.3-.6s-1 .2-1.3.5l-1.1 1H7.6c-1.3 0-2.4 1.1-2.4 2.4V7L2.6 9.7c-.4.3-.6.8-.6 1.3s.2 1 .5 1.3l2.7 2.8v9.1c0 1.3 1.1 2.4 2.4 2.4H22c1.3 0 2.4-1.1 2.4-2.4v-4.8l3-2.9c.4-.4.6-.8.6-1.3zM18.9 4l3 3h-3V4zm-6.4-3c.4-.4 1.1-.4 1.6 0l.5.5H12l.5-.5zM3 11.8c-.2-.2-.3-.5-.3-.8s.1-.6.3-.8l2.2-2.1v6L3 11.8zm19 13.3H7.6c-.5 0-.9-.4-.9-.9V3.9c0-.5.4-.9.9-.9h9.8v4.8c0 .4.3.7.7.7H23v15.7c0 .5-.5.9-1 .9zm4.9-9.1l-2.5 2.4v-6.7l2.5 2.6c.5.5.5 1.2 0 1.7z"/><path pid="1" d="M20.4 11.1H9.2c-.2 0-.4.2-.4.4s.2.4.4.4h11.2c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM20.4 13.7H9.2c-.2 0-.4.2-.4.4s.2.4.4.4h11.2c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM20.4 16.3H9.2c-.2 0-.4.2-.4.4.1.1.2.3.4.3h11.2c.2 0 .4-.2.4-.4s-.2-.3-.4-.3zM20.4 18.9H9.2c-.2 0-.4.2-.4.4s.2.4.4.4h11.2c.2 0 .4-.2.4-.4 0-.3-.2-.4-.4-.4z"/>',
	},
});
