/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	live: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M24.9 23.9c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1 2.5-2.5 3.9-5.8 3.9-9.4 0-3.5-1.4-6.9-3.9-9.4-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0 2.8 2.8 4.3 6.5 4.3 10.4 0 3.9-1.5 7.6-4.3 10.4-.3.4-.4.4-.6.4zM5.1 23.9c-.2 0-.4-.1-.5-.2A14.6 14.6 0 01.3 13.3c0-3.9 1.5-7.6 4.3-10.4.3-.3.8-.3 1.1 0s.3.8 0 1.1c-2.5 2.5-3.9 5.8-3.9 9.4 0 3.5 1.4 6.9 3.9 9.4.3.3.3.8 0 1.1h-.6z"/><path pid="1" d="M22.2 21.3c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1 1.8-1.8 2.8-4.2 2.8-6.7s-1-4.9-2.8-6.7c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0 2.1 2.1 3.2 4.8 3.2 7.8 0 2.9-1.1 5.7-3.2 7.8-.2.1-.4.2-.6.2zM7.7 21.3c-.2 0-.4-.1-.5-.2C5.1 19 4 16.2 4 13.3s1.1-5.7 3.2-7.8c.3-.3.8-.3 1.1 0s.3.8 0 1.1c-1.8 1.8-2.8 4.2-2.8 6.7s1 4.9 2.8 6.7c.3.3.3.8 0 1.1-.2.1-.4.2-.6.2z"/><path pid="2" d="M19.6 18.7c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1 1.1-1.1 1.7-2.5 1.7-4.1 0-1.5-.6-3-1.7-4.1-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0 1.4 1.4 2.1 3.2 2.1 5.1 0 1.9-.8 3.8-2.1 5.1-.2.3-.4.4-.6.4zM10.4 18.7c-.2 0-.4-.1-.5-.2-1.4-1.4-2.1-3.2-2.1-5.1 0-1.9.8-3.8 2.1-5.1.3-.3.8-.3 1.1 0s.3.8 0 1.1c-1.1 1.1-1.7 2.5-1.7 4.1 0 1.5.6 3 1.7 4.1.3.3.3.8 0 1.1-.3-.1-.5 0-.6 0zM15 16.8c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm0-5.5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>',
	},
});
