import 'flatpickr/dist/flatpickr.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@/components/Icons';

import Vue from 'vue';
import CompositionAPI from '@vue/composition-api';
import PortalVue from 'portal-vue';
import VueMasonry from 'vue-masonry-css';
import SvgIcon from 'vue-svgicon';
import Notification from '@/plugins/Notifications';

export function setupPlugins() {
	Vue.use(Notification);
	Vue.use(CompositionAPI);
	Vue.use(PortalVue);
	Vue.use(VueMasonry);
	Vue.use(SvgIcon, {
		tagName: 'Icon',
	});
}

declare module '@vue/composition-api' {
	interface SetupContext {
		readonly refs: { [key: string]: Vue | Element | Vue[] | Element[] };
	}
}
