/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-rotate-ccw': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M1 4v6h6"/><path pid="1" d="M3.51 15a9 9 0 102.13-9.36L1 10"/>',
	},
});
