/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-rotate-cw': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M23 4v6h-6"/><path pid="1" d="M20.49 15a9 9 0 11-2.12-9.36L23 10"/>',
	},
});
