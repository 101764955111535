/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-repeat': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M17 1l4 4-4 4"/><path pid="1" d="M3 11V9a4 4 0 014-4h14M7 23l-4-4 4-4"/><path pid="2" d="M21 13v2a4 4 0 01-4 4H3"/>',
	},
});
