/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-corner-right-up': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M10 9l5-5 5 5"/><path pid="1" d="M4 20h7a4 4 0 004-4V4"/>',
	},
});
