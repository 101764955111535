/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-edit-2': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>',
	},
});
