/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-trending-down': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M23 18l-9.5-9.5-5 5L1 6"/><path pid="1" d="M17 18h6v-6"/>',
	},
});
