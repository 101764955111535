/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-music': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M9 18V5l12-2v13"/><circle pid="1" cx="6" cy="18" r="3"/><circle pid="2" cx="18" cy="16" r="3"/>',
	},
});
