/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-underline': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M6 3v7a6 6 0 006 6 6 6 0 006-6V3M4 21h16"/>',
	},
});
