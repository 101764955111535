/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	stats: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M28.4 25.2H2.3V.9c0-.4-.3-.7-.7-.7S.9.5.9.9v25c0 .4.3.7.7.7h26.7c.4 0 .7-.3.7-.7.1-.4-.2-.7-.6-.7z"/><path pid="1" d="M3.9 24.1c.1 0 .1 0 .2-.1l4.1-2.9c.4.5 1.1.8 1.8.8 1.4 0 2.5-1.1 2.5-2.5 0-.9-.5-1.7-1.2-2.1l2.6-6.1c.2.1.4.1.6.1.6 0 1.1-.2 1.5-.5l2.7 2.7c-.3.4-.5.9-.5 1.5 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-.9-.5-1.7-1.3-2.2l4-7.8c.3.1.5.1.8.1 1.4 0 2.5-1.1 2.5-2.5S28 .2 26.6.2c-1.4 0-2.5 1.1-2.5 2.5 0 .8.4 1.6 1 2l-4 7.9c-.2 0-.3-.1-.5-.1-.6 0-1.1.2-1.5.5l-2.7-2.7c.3-.4.5-.9.5-1.5 0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5c0 .9.5 1.7 1.2 2.1L10.6 17c-.2-.1-.4-.1-.6-.1-1.4 0-2.5 1.1-2.5 2.5 0 .4.1.8.3 1.1l-4.1 2.9c-.2.1-.2.3-.1.5.1.1.2.2.3.2zM26.6 1.6c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1c-.1 0-.3 0-.4-.1l-.1-.1H26c-.3-.2-.5-.5-.5-.9 0-.6.5-1.1 1.1-1.1zm-4.9 13.3c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1c0-.6.5-1.1 1.1-1.1s1.1.6 1.1 1.1zm-7.2-7.1c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1zM10 18.3c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1z"/>',
	},
});
