/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-briefcase': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="2" y="7" width="20" height="14" rx="2" ry="2"/><path pid="1" d="M16 21V5a2 2 0 00-2-2h-4a2 2 0 00-2 2v16"/>',
	},
});
