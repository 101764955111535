export default [
	{
		path: '/media/gallery',
		component: () => import('@/app/media/views/MediaListView.vue'),
	},
	{
		path: '/media/images/uploads/:ids',
		component: () => import('@/app/media/views/ImageUploadsView.vue'),
	},
	{
		path: '/media/images/:id',
		component: () => import('@/app/media/views/ImageEditView.vue'),
	},
];
