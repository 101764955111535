/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'carret-down': {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M16.3 20.4L27.6 9.2c.8-.8.8-2.2 0-3-.8-.8-2.2-.8-3 0l-9.7 9.7-9.7-9.6c-.8-.8-2.2-.8-3 0-.4.4-.6 1-.6 1.5s.2 1.1.6 1.5l11.2 11.1c.8.8 2.1.8 2.9 0z"/>',
	},
});
