/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	pin: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M14.5 6.1c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2-1.8-4.2-4.2-4.2zm0 7.7c-2 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5S18 8.4 18 10.3s-1.5 3.5-3.5 3.5z"/><path pid="1" d="M14.5 0C8.9 0 4.2 4.6 4.2 10.3c0 5.5 9.4 15.7 9.8 16.1.1.1.3.2.5.2s.4-.1.5-.2c.4-.4 9.8-10.6 9.8-16.1C24.8 4.6 20.2 0 14.5 0zm0 24.8c-.8-.9-2.5-2.9-4.2-5.2-3-4.1-4.6-7.4-4.6-9.4 0-4.9 4-8.9 8.9-8.9s8.9 4 8.9 8.9c0 2-1.6 5.2-4.6 9.4-1.8 2.3-3.6 4.3-4.4 5.2z"/>',
	},
});
