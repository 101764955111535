/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-toggle-right': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="1" y="5" width="22" height="14" rx="7" ry="7"/><circle pid="1" cx="16" cy="12" r="3"/>',
	},
});
