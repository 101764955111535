/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-rss': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M4 11a9 9 0 019 9M4 4a16 16 0 0116 16"/><circle pid="1" cx="5" cy="19" r="1"/>',
	},
});
