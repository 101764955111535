/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'beachvolley-ball': {
		width: 16,
		height: 16,
		viewBox: '0 0 26.7 26.7',
		data:
			'<path pid="0" d="M13.3 0C6 0 0 6 0 13.3c0 7.4 6 13.3 13.3 13.3 7.4 0 13.3-6 13.3-13.3C26.7 6 20.7 0 13.3 0zm11.9 12.1c-4.5-4.3-10.1-5.9-12-6.3.1-1.4.3-2.9.7-4.4 6 .3 10.7 4.9 11.3 10.7zM8.1 16.5C7.4 14.6 5.7 9 7.3 3.1c1.5-.9 3.3-1.5 5.2-1.6-1.4 5.2-.3 10.2.1 11.7-.7.5-2.3 2-4.5 3.3zM6.4 3.6c-1.3 5.9.4 11.4 1 13.2-1.4.8-3 1.4-4.7 1.8-.8-1.6-1.3-3.4-1.3-5.3 0-4 2-7.5 5-9.7zM3.4 20c5.2-1.4 9-4.8 10.1-5.8.9.3 3.2 1 5.7 2.6-1.4 1.5-5.7 5.6-11.6 7.1-1.7-1-3.1-2.3-4.2-3.9zm9.9 5.3c-1.7 0-3.3-.4-4.8-1 5.8-1.7 10-5.7 11.3-7.2 1.2.8 2.3 1.7 3.4 2.9-2.1 3.2-5.7 5.3-9.9 5.3zM24 18.7c-3.8-3.9-8.6-5.5-10.1-5.9-.2-.9-.7-3.3-.7-6.2 2 .5 7.7 2.2 12 6.7v.1c.1 1.9-.4 3.7-1.2 5.3z" _fill="#1d1d1b"/>',
	},
});
