/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-pause-circle': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="12" r="10"/><path pid="1" d="M10 15V9M14 15V9"/>',
	},
});
