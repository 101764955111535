import EventTarget from '@ungap/event-target';

class EventEmitter extends EventTarget {
	on(
		name: string,
		callback: (payload: unknown, event: CustomEvent) => void
	): () => void {
		this.addEventListener(name, (event: CustomEvent) =>
			callback(event.detail, event)
		);

		return () => {
			this.removeEventListener(name, (event: CustomEvent) =>
				callback(event.detail, event)
			);
		};
	}

	once(
		name: string,
		callback: (payload: unknown, event: CustomEvent) => void
	): void {
		this.addEventListener(
			name,
			(event: CustomEvent) => callback(event.detail, event),
			{ once: true }
		);
	}

	emit(eventName: string, payload?: unknown) {
		console.log(`[Event] ${eventName} emitted!`);
		this.dispatchEvent(new CustomEvent(eventName, { detail: payload }));
	}
}

export const Event = new EventEmitter();
