/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	arrow: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M29.7 12.6l-8.8-8.8c-.4-.4-1-.4-1.3 0-.4.4-.4 1 0 1.3l7.2 7.2H1c-.5 0-1 .4-1 1 0 .5.4 1 1 1h25.8l-7.2 7.2c-.4.4-.4 1 0 1.3.2.2.4.3.7.3.2 0 .5-.1.7-.3l8.8-8.8c.3-.5.3-1.1-.1-1.4z"/>',
	},
});
