/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-copy': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="9" y="9" width="13" height="13" rx="2" ry="2"/><path pid="1" d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1"/>',
	},
});
