/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-instagram': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<rect pid="0" x="2" y="2" width="20" height="20" rx="5" ry="5"/><path pid="1" d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zM17.5 6.5h0"/>',
	},
});
