/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	success: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M11.5 24.2c-.9 0-1.7-.3-2.3-1L1 14.9c-1.3-1.3-1.3-3.3 0-4.6C2.3 9 4.3 9 5.6 10.3l6 6L24.5 3.4c1.3-1.3 3.3-1.3 4.6 0 1.3 1.3 1.3 3.3 0 4.6L13.8 23.2c-.6.6-1.4 1-2.3 1zm0-1.3c.5 0 1-.2 1.4-.6L28.2 7.1c.8-.8.8-2 0-2.8-.8-.8-2-.8-2.8 0L12 17.7c-.2.2-.7.2-.9 0l-6.4-6.4c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l8.3 8.3c.3.3.8.5 1.3.5z"/>',
	},
});
