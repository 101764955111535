/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	key: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M28.3 3.4c0-.9-.4-1.8-1-2.4-.6-.6-1.5-1-2.4-1-.9 0-1.8.4-2.4 1L11.7 11.7c-2.7-.9-5.7-.2-7.8 1.9C2.5 15 1.7 16.9 1.7 19c0 2 .8 4 2.2 5.4 1.5 1.5 3.4 2.2 5.4 2.2 2 0 3.9-.7 5.4-2.2 2-2 2.7-5.1 1.9-7.8l.8-.8c1.1.6 2.5.5 3.4-.4l5.7-5.7c.9-.9 1.1-2.3.4-3.4l.4-.4c.6-.7 1-1.5 1-2.5zm-2 1.5l-.8.8c-.3.3-.3.7 0 .9.5.6.5 1.5 0 2l-5.7 5.7c-.5.5-1.4.6-2 0-.3-.3-.7-.3-.9 0l-1.5 1.5c-.2.2-.2.5-.2.7.9 2.3.4 5-1.4 6.7-2.4 2.4-6.4 2.4-8.9 0C3.7 22.2 3 20.7 3 19c0-1.7.7-3.3 1.8-4.4 1.2-1.2 2.8-1.8 4.4-1.8.8 0 1.6.1 2.3.4.2.1.5 0 .7-.2l11-11c.8-.8 2.1-.8 3 0 1 .8 1 2.1.1 2.9z"/><path pid="1" d="M7.7 18.4c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2zm0 3.8c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6zM24.8 2.9l-11 11c-.1.1-.1.3 0 .5.1.1.1.1.2.1s.2 0 .2-.1l11-11c.1-.1.1-.3 0-.5 0-.2-.2-.2-.4 0zM23 7.9l-4.2 4.2c-.1.1-.1.3 0 .5.1.1.1.1.2.1s.2 0 .2-.1l4.2-4.2c.1-.1.1-.3 0-.5-.1-.1-.3-.1-.4 0z"/>',
	},
});
