/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	cross: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M5.6 5.6l8 8 3.1 3.1 6 6c.5.5 1.2.5 1.7 0s.5-1.2 0-1.7l-6-6-3.1-3.1-8-8c-.5-.5-1.2-.5-1.7 0s-.5 1.2 0 1.7z"/><path pid="1" d="M7.3 22.7l8-8 3.1-3.1 6-6c.5-.5.5-1.2 0-1.7s-1.2-.5-1.7 0l-6 6-3.1 3.1-8 8c-.5.5-.5 1.2 0 1.7s1.3.4 1.7 0z"/>',
	},
});
