export default [
	{
		path: '/volley/transfers',
		component: () => import('@/app/transfers/views/TransferListView.vue'),
	},
	{
		path: '/volley/transfers/:id',
		component: () => import('@/app/transfers/views/TransferView.vue'),
	},
];
