/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	physical: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M27.8 7l-2.5-2.5.3-.3c.3-.2.4-.5.4-.8 0-.3-.1-.6-.4-.9-.2-.2-.5-.4-.9-.4s-.6.1-.9.4l-.3.3L21.2.5c-.3-.3-.7-.5-1.1-.5s-.9.2-1.2.5c-.2.2-.3.3-.4.5-.2-.1-.5-.1-.8-.1-.7 0-1.4.3-1.9.8-1.1 1.1-1.1 2.8 0 3.8l2.6 2.6-8.6 8.6-2.6-2.6c-.5-.5-1.2-.8-1.9-.8A2.732 2.732 0 002.6 16c0 .3 0 .5.1.8-.2.1-.4.2-.5.4-.3.3-.5.7-.5 1.2s.2.9.5 1.2l2.3 2.3-.3.3c-.2.2-.4.5-.4.9 0 .3.1.6.4.9.2.2.5.4.9.4.3 0 .6-.1.9-.4l.3-.3 2.5 2.5c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5.2-.2.3-.3.4-.5.2.1.5.1.8.1.7 0 1.4-.3 1.9-.8 1.1-1.1 1.1-2.8 0-3.8l-2.7-2.7 8.6-8.6 2.7 2.7c.5.5 1.2.8 1.9.8s1.4-.3 1.9-.8c.7-.7 1-1.8.7-2.7.2-.1.4-.2.5-.4.3-.3.5-.7.5-1.2-.1-.5-.3-.9-.6-1.3zm-3.4-4c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-.3.3-.7-.7.3-.3zM5.5 23.4c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l.2-.3.7.7-.2.3zm7.7.5c-.5.5-1.3.5-1.8 0l-.2-.2c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1 .1.1.1.3 0 .4-.1.1-.3.1-.4 0l-6.5-6.5c-.1-.1-.1-.2-.1-.2s0-.1.1-.2.1-.1.2-.1.2 0 .2.1c.3.3.7.3 1 0 .1-.1.2-.3.2-.5s-.1-.4-.2-.5l-.2-.2c-.5-.5-.5-1.3 0-1.8.2-.2.6-.4.9-.4.3 0 .7.1.9.4l7 7c.4.4.4 1.2-.1 1.7zm-2.2-6l-.7-.7 8.6-8.6.7.7-8.6 8.6zm15.8-9.4c-.1.1-.3.1-.4 0-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l.2.2c.5.5.5 1.3 0 1.8-.2.2-.6.4-.9.4-.3 0-.7-.1-.9-.4l-7-7c-.5-.5-.5-1.3 0-1.8.2-.2.6-.4.9-.4.3 0 .7.1.9.4l.2.2c.3.3.7.3 1 0 .3-.3.3-.7 0-1-.1-.1-.1-.3 0-.4.1-.1.2-.1.2-.1s.1 0 .2.1L26.8 8c.1.1.1.2.1.2s0 .2-.1.3z"/><path pid="1" d="M21 3.7c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l3.3 3.3c.1.1.2.1.2.1s.2 0 .2-.1c.1-.1.1-.4 0-.5L21 3.7zM5.3 18.9c-.1.1-.1.4 0 .5l3.3 3.3c.1.1.2.1.2.1.1 0 .2 0 .2-.1.1-.1.1-.4 0-.5l-3.3-3.3c0-.2-.2-.2-.4 0z"/>',
	},
});
