/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	communication: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M17.8 17.4c1.9 0 3.5-1.6 3.5-3.5V5.6c0-1.9-1.6-3.5-3.5-3.5H3.5C1.6 2.1 0 3.6 0 5.6v8.3c0 1.9 1.6 3.5 3.5 3.5h3.3l-1.1 3.2c-.1.3 0 .5.2.7.1.1.3.1.4.1.1 0 .2 0 .3-.1l6-4h5.2zm-5.7-1.1l-4.6 3 .8-2.3c.1-.2 0-.4-.1-.6-.1-.2-.3-.3-.5-.3H3.5c-1.2 0-2.2-1-2.2-2.2V5.6c0-1.2 1-2.2 2.2-2.2h14.3c1.2 0 2.2 1 2.2 2.2v8.3c0 1.2-1 2.2-2.2 2.2h-5.3c-.1.1-.2.1-.4.2z"/><path pid="1" d="M26.5 6.2h-2.8c-.3 0-.6.3-.6.6s.3.6.6.6h2.8c1.2 0 2.2 1 2.2 2.2v8.3c0 1.2-1 2.2-2.2 2.2h-4.2c-.2 0-.4.1-.5.3-.1.2-.1.4-.1.6l.8 2.3-4.6-3c-.1-.1-.2-.1-.3-.1h-5.3c-.3 0-.6.3-.6.6s.3.6.6.6h5.1l6 4c.1.1.2.1.3.1.1 0 .3 0 .4-.1.2-.2.3-.5.2-.7l-1.1-3.2h3.3c1.9 0 3.5-1.6 3.5-3.5V9.7c0-1.9-1.6-3.5-3.5-3.5zM16.4 6.9H4.8c-.2 0-.3.1-.3.3s.1.3.3.3h11.7c.2 0 .3-.1.3-.3s-.2-.3-.4-.3zM16.4 9.5H4.8c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h11.7c.2 0 .3-.1.3-.3-.1-.2-.2-.3-.4-.3zM16.4 12H4.8c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h11.7c.2 0 .3-.1.3-.3-.1-.1-.2-.3-.4-.3z"/>',
	},
});
