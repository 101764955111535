/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-crop': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M6.13 1L6 16a2 2 0 002 2h15"/><path pid="1" d="M1 6.13L16 6a2 2 0 012 2v15"/>',
	},
});
