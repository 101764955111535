/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	development: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M28.1.3L28 .2c-.2-.1-.4-.2-.6-.2-.2 0-4 0-8.8 2.3-3.2 1.5-7.4 4.3-11.2 9.3 0 0-.1 0-.1-.1-3.3-.4-5.5 2.1-5.6 2.3-.1.1-.1.4 0 .5l2.4 2.4c-.3.5-.5 1-.8 1.5-.1.3-.1.6.1.8L9 24.6c.1.1.3.2.5.2.1 0 .2 0 .3-.1.5-.2 1-.5 1.5-.8l2.4 2.4c.1.1.2.1.3.1.1 0 .2 0 .2-.1.1-.1 2.7-2.3 2.3-5.6 0-.1 0-.1-.1-.1 5-3.7 7.8-7.9 9.3-11.2C28 4.5 28 .8 28 .6c.3.1.2-.1.1-.3zM2.6 14c.6-.6 2.2-2 4.4-1.8-.8 1.2-1.6 2.4-2.4 3.8l-2-2zm11.7 11.7l-2-2c1.4-.8 2.7-1.6 3.8-2.4.2 2.2-1.2 3.8-1.8 4.4zm-4.4-2.4L5 18.4c3.7-7.7 8.7-11.8 12.7-14 .5 1.4 1.3 2.6 2.4 3.7 1.1 1.1 2.4 1.9 3.8 2.4-2.3 4.3-6.5 9.1-14 12.8zM24.3 9.9c-1.4-.5-2.6-1.3-3.7-2.3-1-1-1.8-2.2-2.2-3.5.3-.1.6-.3.8-.4 3.5-1.6 6.4-2 7.6-2.1-.1 1.2-.6 4.5-2.5 8.3z"/><path pid="1" d="M15.1 11.1c-.6.6-.6 1.6 0 2.2.3.3.7.4 1.1.4s.8-.1 1.1-.4c.6-.6.6-1.6 0-2.2-.7-.6-1.6-.6-2.2 0zm1.6 1.6c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1.2-.2.4-.2.6-.2.2 0 .4.1.6.2.2.3.2.8-.1 1.1zM12.2 16.2c-.3-.3-.6-.4-.8-.4-.3 0-.6.1-.8.3l-2.7 2.7c-.2.2-.3.5-.3.8s.1.6.3.8H8c.2.2.5.3.8.3.3 0 .5-.1.8-.3l2.7-2.7c.3-.4.3-1.1-.1-1.5zm-.5 1L9 19.9c-.1.1-.3.1-.5 0l-.1-.1c-.1-.1-.1-.1-.1-.2s0-.2.1-.2l2.7-2.7c.1-.1.1-.1.2-.1s.2 0 .2.1l.1.1c.2.1.2.3.1.4z"/>',
	},
});
