/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-smile': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="12" r="10"/><path pid="1" d="M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01"/>',
	},
});
