/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	globe: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M15 0C7.7 0 1.7 6 1.7 13.3s6 13.3 13.3 13.3 13.3-6 13.3-13.3S22.3 0 15 0zm9.3 20.7c-1-.6-2.1-1-3.2-1.4.8-1.8 1.3-3.7 1.3-5.6h4.5c-.1 2.6-1 5-2.6 7zm-21.2-7h4.5c.1 2 .5 4 1.4 5.8-1.1.4-2.1.9-3.2 1.4-1.6-2-2.6-4.5-2.7-7.2zm2.7-7.9c1 .6 2.1 1.1 3.2 1.4-.9 1.8-1.3 3.7-1.4 5.7H3.1c.1-2.7 1.1-5.1 2.7-7.1zm9.6 2.5c1.7 0 3.4-.3 5.1-.7.8 1.7 1.2 3.5 1.3 5.4h-6.3V8.3zm0-.7V1.8c2 1.3 3.6 3.1 4.7 5.2-1.5.4-3.1.6-4.7.6zm-.8 0c-1.6-.1-3.2-.3-4.7-.8 1.1-2 2.7-3.8 4.7-5.1v5.9zm0 .7V13H8.3c.1-2 .5-3.8 1.3-5.5 1.7.5 3.3.7 5 .8zm-6.3 5.4h6.3v4.7c-1.7.1-3.4.3-5 .8-.8-1.7-1.2-3.6-1.3-5.5zm6.3 5.4v5.8c-2-1.3-3.6-3-4.6-5 1.5-.5 3.1-.8 4.6-.8zm.8 0c1.6 0 3.2.2 4.7.6-1.1 2-2.7 3.8-4.7 5.1v-5.7zm0-.7v-4.7h6.3c-.1 1.9-.5 3.7-1.3 5.4-1.6-.5-3.3-.7-5-.7zm7-5.5c0-1.9-.5-3.8-1.3-5.6 1.1-.3 2.2-.8 3.2-1.3 1.5 1.9 2.4 4.3 2.5 7h-4.4zm1.5-7.5c-1 .5-2 1-3.1 1.3-1.1-2.1-2.6-3.9-4.6-5.2 3.1.3 5.8 1.8 7.7 3.9zM13.8 1.5c-1.9 1.3-3.4 3.1-4.5 5.1-1-.4-2-.8-3-1.3 1.9-2.1 4.5-3.5 7.5-3.8zM6.3 21.4c1-.5 2-1 3-1.3 1.1 2 2.6 3.7 4.5 5.1-3-.4-5.6-1.8-7.5-3.8zm9.9 3.7c1.9-1.4 3.5-3.1 4.6-5.2 1.1.3 2.1.8 3.1 1.3-2 2.2-4.7 3.6-7.7 3.9z"/>',
	},
});
