






























































































import Vue from 'vue';

const Notification = Vue.extend({
	name: 'Notification',

	props: {
		notification: {
			required: true,
		},
	},

	data: () => ({
		show: false,
	}),

	created() {
		setTimeout(() => {
			this.show = true;
		}, 0);
	},

	methods: {
		close() {
			this.show = false;

			setTimeout(() => {
				this.$emit('close');
			}, 150);
		},
	},
});

export default Notification;
