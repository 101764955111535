/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	attachment: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M10.8 21.1c-.9.9-2 1.4-3.3 1.4-1.2 0-2.4-.5-3.3-1.4s-1.4-2-1.4-3.3c0-1.2.5-2.4 1.4-3.3l6.1-6.1c1.5-1.5 3.9-1.8 5.7-.7.3.2.6.4.8.7 1.8 1.8 1.8 4.8 0 6.6-.3.3-.3.7 0 1 .1.1.3.2.5.2s.4-.1.5-.2c2.4-2.4 2.4-6.2 0-8.5-.3-.3-.7-.6-1.1-.9-2.4-1.5-5.5-1.1-7.5.9l-6.1 6.1c-1.1 1.1-1.8 2.7-1.8 4.3 0 1.6.6 3.1 1.8 4.3C4.2 23.3 5.8 24 7.4 24c1.6 0 3.1-.6 4.3-1.8.3-.3.3-.7 0-1-.1-.4-.6-.4-.9-.1z"/><path pid="1" d="M26.7 4.5c-2.4-2.4-6.2-2.4-8.5 0-.3.3-.3.7 0 1 .3.3.7.3 1 0 1.8-1.8 4.8-1.8 6.6 0 .9.9 1.4 2 1.4 3.3 0 1.2-.5 2.4-1.4 3.3l-6.1 6.1c-1.5 1.5-3.9 1.8-5.7.7-.3-.2-.6-.4-.8-.7-.9-.9-1.4-2-1.4-3.3 0-1.2.5-2.4 1.4-3.3.3-.3.3-.7 0-1-.3-.3-.7-.3-1 0-1.1 1.1-1.8 2.7-1.8 4.3 0 1.6.6 3.1 1.8 4.3.3.3.7.6 1.1.9 1 .6 2.1.9 3.2.9 1.6 0 3.1-.6 4.3-1.8l6.1-6.1c1.1-1.1 1.8-2.7 1.8-4.3-.2-1.6-.8-3.1-2-4.3z"/>',
	},
});
