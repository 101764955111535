/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'tip-github': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<path pid="0" d="M11.999.5C5.649.5.5 5.648.5 12c0 5.082 3.294 9.392 7.865 10.914.574.103.756-.236.756-.541 0-.274.006-1.037 0-1.997-3.198.694-3.861-1.515-3.861-1.515-.523-1.329-1.275-1.682-1.275-1.682-1.045-.714.077-.699.077-.699 1.153.08 1.762 1.184 1.762 1.184 1.026 1.758 2.691 1.25 3.346.956.106-.742.402-1.251.731-1.536-2.554-.292-5.238-1.277-5.238-5.686 0-1.255.448-2.281 1.184-3.086-.118-.289-.514-1.46.112-3.043 0 0 .967-.309 3.162 1.18a11.011 11.011 0 012.88-.388c.976.005 1.96.132 2.88.388 2.195-1.488 3.159-1.18 3.159-1.18.627 1.583.232 2.754.114 3.043.736.805 1.183 1.831 1.183 3.086 0 4.42-2.689 5.391-5.251 5.674.412.357.787 1.047.787 2.12v3.184c0 .308.186.647.77.536C20.209 21.389 23.5 17.08 23.5 12 23.5 5.648 18.352.5 11.999.5z"/>',
	},
});
