/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-list': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data: '<path pid="0" d="M8 6h13M8 12h13M8 18h13M3 6h0M3 12h0M3 18h0"/>',
	},
});
