/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	cog: {
		width: 16,
		height: 16,
		viewBox: '0 0 30 26.6',
		data:
			'<path pid="0" d="M15 17.5c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2zm0-7.7c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5z" _fill="#1d1d1b"/><path pid="1" d="M16.8 26.6h-3.4c-1.5 0-2.8-1.2-2.8-2.8v-.6c0-.5-.3-.9-.7-1.2-.4-.3-.9-.3-1.4 0l-.5.3c-1.3.7-3 .3-3.8-1.1l-1.6-2.9c-.7-1.3-.3-3 1.1-3.8l.5-.3c.5-.2.8-.7.8-1.2s-.2-.9-.7-1.2l-.5-.3c-.6-.4-1.1-1-1.2-1.7-.2-.7 0-1.5.3-2.1l1.8-2.9c.8-1.3 2.6-1.7 3.8-.8.5.2 1 .2 1.5 0 .4-.2.7-.7.7-1.2 0-1.5 1.2-2.8 2.8-2.8h3.4c1.5 0 2.8 1.2 2.8 2.8v.6c0 .5.3.9.7 1.2.4.3.9.3 1.4 0l.5-.3c1.3-.7 3-.3 3.8 1.1l1.6 2.9c.4.6.4 1.4.2 2.1-.2.7-.7 1.3-1.3 1.7l-.6.3c-.4.2-.7.7-.7 1.2s.2.9.7 1.2l.5.3c.6.4 1.1 1 1.2 1.7.2.7 0 1.5-.3 2.1l-1.8 2.9c-.8 1.3-2.5 1.7-3.8.9-.4-.3-1-.3-1.4 0-.4.2-.7.7-.7 1.2-.1 1.4-1.4 2.7-2.9 2.7zm-7.5-6.2c.5 0 1 .1 1.4.4.9.5 1.4 1.4 1.4 2.4v.6c0 .8.6 1.4 1.4 1.4h3.4c.8 0 1.4-.6 1.4-1.4 0-1 .5-1.9 1.4-2.4.9-.5 1.9-.5 2.8.1.6.4 1.5.2 1.9-.4l1.8-2.9c.2-.3.3-.7.2-1-.1-.4-.3-.7-.6-.9l-.5-.3c-.8-.5-1.3-1.4-1.3-2.4s.6-1.9 1.4-2.4l.5-.3c.3-.2.6-.5.7-.8.1-.4.1-.7-.1-1.1l-1.8-3c-.4-.7-1.2-.9-1.9-.5l-.5.3c-.9.5-1.9.5-2.8 0-.9-.5-1.4-1.4-1.4-2.4v-.6c0-.8-.6-1.4-1.4-1.4h-3.4c-.8 0-1.4.6-1.4 1.4 0 1-.5 1.9-1.4 2.4-.8.5-1.8.5-2.7-.1-.6-.4-1.5-.2-1.9.5L4.1 8.4c-.2.3-.2.7-.1 1.1.1.4.3.7.6.9l.5.3c.8.5 1.3 1.4 1.3 2.4S5.8 15 5 15.5l-.5.3c-.7.4-.9 1.2-.5 1.9l1.6 2.9c.2.3.5.6.8.7.4.1.7.1 1.1-.1l.5-.3c.4-.3.8-.5 1.3-.5z"/>',
	},
});
