/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
	'fe-more-vertical': {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		data:
			'<circle pid="0" cx="12" cy="12" r="1"/><circle pid="1" cx="12" cy="5" r="1"/><circle pid="2" cx="12" cy="19" r="1"/>',
	},
});
