export default [
	{
		path: '/',
		component: () => import('@/app/core/views/DashboardView.vue'),
	},
	{
		path: '/support',
		component: () => import('@/app/core/views/SupportView.vue'),
	},
	{
		path: '*',
		component: () => import('@/app/core/views/ErrorView.vue'),
	},
];
